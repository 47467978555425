import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  pl: {
    translation: {
      // Menu
      "About us": "O nas",
      "Contact": "Kontakt",
      "Dark mode": "Tryb ciemny",
      "Light mode": "Tryb jasny",

      // Welcome bar
      "Sweeten your health": "Osłodź swoje zdrowie",
      "If you can't measure it, you can't improve it.": "Jeśli nie możesz czegoś zmierzyć, nie możesz tego poprawić.",
      "Get on Google Play": "Pobierz z Google Play",
      "Get on App Store": "Pobierz z App Store",

      // App shots
      "App screen": "Ekran aplikacji",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  // lng: "en",
  lng: "pl",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
