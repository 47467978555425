import React, { useState, useEffect } from 'react';
import Header from './components/header/header';
import WelcomeBar from './components/welcomeBar/welcomeBar';
import AppShots from './components/appShots/appShots';
import Drop from './assets/drop.png';
import './App.css';
import { ThemeContext } from './contexts/theme.context';
import Modal from './components/modal/modal';
import { ModalContext } from './contexts/modal.context';

function App() {
  const [loading, setLoading] = useState(document.readyState !== "complete");
  const [darkTheme, setDarkTheme] = useState(() => {
    let localValue = localStorage.getItem("theme");
    if (localValue) {
      return localValue === 'true';
    } else {
      return window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
  });
  const [modalContent, setModalContent] = useState<React.JSX.Element | null>(null);

  useEffect(() => {
    const onReady = () => setLoading(false);

    if (document.readyState === "complete") {
      onReady();
    } else {
      document.addEventListener("readystatechange", onReady);
      return () => document.removeEventListener("readystatechange", onReady);
    }
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", darkTheme ? "dark" : "light");
    localStorage.setItem("theme", darkTheme.toString());
  }, [darkTheme]);

  if (loading) return (
    <div className="loader">
      <img src={Drop} alt="loading..." />
    </div>
  );

  return (
    <div className="app-wrapper">
      <ThemeContext.Provider value={{ darkTheme, setDarkTheme }}>
        <ModalContext.Provider value={{ modalContent, setModalContent }}>
          <Header />
          <WelcomeBar />
          <AppShots />
          <Modal />
        </ModalContext.Provider>
      </ThemeContext.Provider>
    </div>
  );
}

export default App;
