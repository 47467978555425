import { createContext } from "react";

interface ModalContextType {
  modalContent: React.JSX.Element | null;
  setModalContent: React.Dispatch<React.SetStateAction<React.JSX.Element | null>>;
}

export const ModalContext = createContext<ModalContextType>({
  modalContent: null,
  setModalContent: () => {},
});
