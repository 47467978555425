import React, { useContext } from 'react';
import './appShots.css';
import AppLogENLight from '../../assets/app/features_en_light.png';
import AppLogENDark from '../../assets/app/features_en_dark.png';
import AppLogPLLight from '../../assets/app/features_pl_light.png';
import AppLogPLDark from '../../assets/app/features_pl_dark.png';
import AppLogENMobile from '../../assets/app/features_en_dark_mobile.png';
import AppLogPLMobile from '../../assets/app/features_pl_dark_mobile.png';
import { ThemeContext } from '../../contexts/theme.context';
import { useTranslation } from 'react-i18next';

export default function AppShots() {
  const { t, i18n } = useTranslation();
  const { darkTheme } = useContext(ThemeContext);

  const getScreenPath = () => {
    if (i18n.language === 'pl') {
      return darkTheme ? AppLogPLDark : AppLogPLLight;
    } else {
      return darkTheme ? AppLogENDark : AppLogENLight;
    }
  };

  const getMobileScreenPath = () => {
    if (i18n.language === 'pl') {
      return AppLogPLMobile;
    } else {
      return AppLogENMobile;
    }
  };

  return (
    <div className="app-shots">
      <img src={getScreenPath()} className="desktop-screen"  alt={t("App screen")} />
      <img src={getMobileScreenPath()} className="mobile-screen" alt={t("App screen")} />
    </div>
  );
}
