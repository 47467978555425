import React from 'react';
import './welcomeBar.css';
import getOnPlayEN from '../../assets/get-on/play-en.png';
import getOnStoreEN from '../../assets/get-on/appstore-en.png';
import getOnPlayPL from '../../assets/get-on/play-pl.png';
import getOnStorePL from '../../assets/get-on/appstore-pl.png';
import { useTranslation } from "react-i18next";

export default function WelcomeBar() {
  const { t, i18n } = useTranslation();

  const getOnPlay = i18n.language === "pl" ? getOnPlayPL : getOnPlayEN;
  const getOnStore = i18n.language === "pl" ? getOnStorePL : getOnStoreEN;

  return (
    <div className="welcome-bar-wrapper">
      <h1>{t("Sweeten your health")}</h1>
      <div className="quote">
        <h3>{t("If you can't measure it, you can't improve it.")}</h3>
        <h4>~William Thomson</h4>
      </div>
      <div className="stores-badges">
        <img src={getOnPlay} alt={t("Get on Google Play")} />
        <img src={getOnStore} alt={t("Get on App Store")} />
      </div>
    </div>
  );
}
