import React, { useContext } from 'react';
import './modal.css';
import { ModalContext } from '../../contexts/modal.context';

export default function Modal() {
  const { modalContent, setModalContent } = useContext(ModalContext);

  const handleClose = () => {
    setModalContent(null);
  };

  return (
    <div
      className="modal-wrapper"
      style={{ ...(!modalContent && { visibility: 'hidden', opacity: 0 }) }}
    >
      <div className="modal">
        <div className="modal_window">
          <i className='modal_close bx bx-x' onClick={handleClose} />
          { modalContent }
        </div>
      </div>
    </div>
  );
}
