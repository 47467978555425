import React, { useContext } from 'react';
import logo from '../../assets/logo.png';
import darkLogo from '../../assets/logo_dark.png';
import './header.css';
import { ModalContext } from '../../contexts/modal.context';
import { ThemeContext } from '../../contexts/theme.context';
import Menu from './menu';

export default function Header() {
  const { darkTheme } = useContext(ThemeContext);
  const { setModalContent } = useContext(ModalContext);

  const handleMobileMenu = () => {
    setModalContent(
      <Menu className="mobile-link-group" />
    );
  };

  return (
    <div>
      <header className="header">
        {darkTheme ? (
          <img src={darkLogo} alt="logo" />
        ) : (
          <img src={logo} alt="logo" />
        )}
        <div className="menu-bar">
          <i className='bx bx-menu menu-icon' onClick={handleMobileMenu} />
          <Menu className="menu-link-group" />
        </div>
      </header>
    </div>
  );
}
