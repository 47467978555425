import React, { useContext } from 'react';
import './header.css';
import { ModalContext } from '../../contexts/modal.context';
import { ThemeContext } from '../../contexts/theme.context';
import { useTranslation } from 'react-i18next';

export default function Menu({ className }: { className: string }) {
  const { t, i18n } = useTranslation();
  const { darkTheme, setDarkTheme } = useContext(ThemeContext);
  const { setModalContent } = useContext(ModalContext);

  const handleContact = () => {
    setModalContent(
      <div>
        <h3>{t("Contact")}</h3>
        <p>TBA</p>
      </div>
    );
  };

  const handleAboutUs = () => {
    setModalContent(
      <div>
        <h3>{t("About us")}</h3>
        <p>TBA</p>
      </div>
    );
  };

  const toggleTheme = () => {
    setDarkTheme(!darkTheme);
  };

  const toggleLanguage = () => {
    i18n.changeLanguage(i18n.language === 'pl' ? 'en' : 'pl');
  };



  return (
    <div className={className}>
      <span onClick={handleAboutUs}>{t("About us")}</span>
      <span onClick={handleContact}>{t("Contact")}</span>
      <span onClick={toggleTheme}>{darkTheme ? t("Light mode") : t("Dark mode")}</span>
      <span onClick={toggleLanguage}>{i18n.language === 'en' ? "Polski" : "English"}</span>
    </div>
  );
}
